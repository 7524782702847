/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* app.js */

img.logo {
  height: 13vmin;
  pointer-events: none;
}

.active {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #c10944; */
  font-size: 16px;
  background-color: #4285f4;
}

.nav-link{
  /* color: #4285f4; */
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.6rem;
  text-decoration: none;
  margin: 10px  10px 10px  10px;

}
.inactive {
  /* color: #fff; */
  text-decoration: none;
}


.color{
  color: white;
}
.showmore{
  background-color: #4285f4;
  color: #F2F2F2;
}
/*----------------------------------- Home -------------------------------*/
.carousel-item {
  height: 535px;
}
img.d-block.w-100.img-fluid {
  height: inherit;
}
.col-sm-3 {
  align-self: center;
}
.rounded{
  width: inherit;
}

/*------------------------------------ About --------------------------------------*/

img.img-fluids.client-imgs.rounded {
  width: 50%;
}

/*--------------------------------- Our Services ----------------------------*/
.col-sm-4 img {
  width: 100%;
  /* height: fit-content; */
  /* margin-top: 40px; */
}

h3#ourservices{
  color: #4285f4;
}
.bg-secondary {
  background-color: #F2F2F2 !important;
}

/* .text-center.card {
  background-color: #D3D3D3 !important;
} */

.content-ceneter{
  align-self: center;
  text-align: -webkit-center;
}

.list{
  text-align: left;
}

/* contact us */
path{
  color: #4285f4;
}

.com{
  align-items: center;
  padding: 4px;
}


figcaption{
  position: inherit;
  bottom: 7rem;
  font-weight: bold;
  font-size: x-large;
}

.technical{
  align-self: center;
}

.manpower{
  align-self: center;
}

.logistics{
  align-self: center;
}
/* contact us */

.contact-form{
  background-color: #4285f4;
}
.send{
  color: #F2F2F2;
  background-color: #343a40;
}

